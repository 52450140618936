import React, { useEffect, useState, useMemo, memo } from 'react'
import { useUser } from 'lib/hooks'
import { Client, query as q } from 'faunadb'

import Link from 'next/link'
import { useRouter } from 'next/router'

type MenuProps = {
  className: string
}

const STUDENTS_BATCH_SIZE = 50

const Menu: React.FunctionComponent<MenuProps> = ({ className }: MenuProps) => {
  const router = useRouter()
  const { user } = useUser({ redirectTo: '/' })
  const [meData, setMeData] = useState(null)
  const [studentsData, setStudentsData] = useState(null)
  const [studentsCache, setStudentsCache] = useState<Map<string, any>>(new Map())

  const client = useMemo(
    () =>
      user?.faunaSecret
        ? new Client({
            domain: 'db.us.fauna.com',
            scheme: 'https',
            secret: user?.faunaSecret,
          })
        : null,
    [user?.faunaSecret],
  )

  const activeStudents = useMemo(
    () => studentsData?.data.sort((a, b) => a.data.name.localeCompare(b.data.name)),
    [studentsData],
  )

  useEffect(() => {
    if (!client) return

    const fetchData = async () => {
      try {
        const res = await client.query(q.Call(q.Function('fetch_me'), [null, false]))
        setMeData(res)
      } catch (error) {
        console.error('FaunaDB Query Error:', error)
      }
    }
    fetchData()
  }, [user?.faunaSecret])

  useEffect(() => {
    if (!client) return
    if (!meData) return
    if (studentsData?.data.length > 0) return
    const fetchData = async () => {
      try {
        const res = await client.query(
          q.Map(
            q.Paginate(
              q.Match(q.Index('students_by_user'), [q.Ref(q.Collection('users'), meData?.ref?.id)]),
              { size: 99 },
            ),
            q.Lambda('x', q.Get(q.Var('x'))),
          ),
        )
        setStudentsData(res)
      } catch (error) {
        console.error('FaunaDB Query Error:', error)
      }
    }
    fetchData()
  }, [user?.faunaSecret, meData])

  return (
    <nav
      className={`${className} hidden md:flex flex-col justify-between overflow-y-auto h-screen `}>
      <div>
        {/* <span className="text-coolGray-700 block mb-4 font-bold text-md">メニュー</span> */}

        <Link
          href="/home"
          className={` mb-8 block text-coolGray-700 hover:underline hover:font-semibold ${
            router.asPath === '/home' && 'font-semibold text-coolGray-900'
          }`}>
          Home・お知らせ
        </Link>

        <span className="text-sm text-coolGray-700 mb-2 block">学籍一覧</span>
        <ul className="mb-6">
          {!studentsData && <span>Loading...</span>}

          {activeStudents?.map((student) => {
            const { name, id, isActive } = student?.data
            const { id: _id } = student?.ref
            if (isActive !== true) return
            return (
              <li key={_id} className="mb-4">
                <Link
                  href={`/student/${_id}/`}
                  className={`text-coolGray-700 hover:underline hover:font-semibold ${
                    router.asPath.includes(_id) && 'font-semibold text-coolGray-900'
                  }`}>
                  {name}
                </Link>
              </li>
            )
          })}
        </ul>

        {meData?.data.isRegistered && (
          <ul>
            <li className="mb-12">
              <Link
                href="/register"
                className={`text-coolGray-700 hover:underline hover:font-semibold ${
                  router.asPath === '/register' && 'font-semibold text-coolGray-900'
                }`}>
                幼児・児童・生徒登録
              </Link>
            </li>

            <li className="mb-16">
              <Link
                href="/invoices"
                className={`text-coolGray-700 hover:underline hover:font-semibold ${
                  router.asPath === '/invoices' && 'font-semibold text-coolGray-900'
                }`}>
                請求書一覧・支払い
              </Link>
            </li>
          </ul>
        )}
      </div>

      <div>
        {meData?.data?.isRegistered && (
          <ul className="mb-12">
            <li className="mb-4">
              <Link
                href="https://docs.google.com/presentation/d/1avCeeWjYOmbWkJl9XG3bTrp8ePn5q8cEbVHQjseagsg"
                target="_blank"
                className={'text-coolGray-700 hover:underline hover:font-semibold'}>
                出願方法
              </Link>
            </li>
            <li className="mb-4">
              <Link
                href="https://docs.google.com/presentation/d/1ZA6swQcSvoJXGf4GL9NfLBkbC0kvbB0lGgYkFNmu6Lk/edit?usp=sharing"
                target="_blank"
                className={'text-coolGray-700 hover:underline hover:font-semibold'}>
                日帰りスキー教室・宿泊スキーキャンプ2024を申し込み方法
              </Link>
            </li>
            <li className="mb-4">
              <Link
                href="/downloads"
                className={`text-coolGray-700 hover:underline hover:font-semibold ${
                  router.asPath === '/downloads' && 'font-semibold text-coolGray-900'
                }`}>
                ファイルダウンロード
              </Link>
            </li>
          </ul>
        )}

        <ul>
          {meData?.data?.isRegistered && (
            <li className="mb-6">
              <Link
                href="/profile"
                className={`text-coolGray-700 hover:underline hover:font-semibold ${
                  router.asPath === '/profile' && 'font-semibold text-coolGray-900'
                } `}>
                保護者情報の変更
              </Link>
            </li>
          )}

          <li>
            <Link
              href="/api/logout"
              className="text-coolGray-700  hover:underline hover:font-semibold block mb-6">
              ログアウト
            </Link>
          </li>
        </ul>
        <span className="text-gray-500 block py-2 text-xs">
          &copy; Japanese Children&apos;s Society, Inc.
        </span>
      </div>
    </nav>
  )
}

export default memo(Menu) // コンポーネント全体をメモ化
